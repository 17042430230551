import React, { useState, useEffect } from 'react';
import LeftNavBar from '../components/LeftNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadPDFFileApi } from '../ServerApi/server'
import { getPdfPage } from '../helper/Helpers';
import _ from "lodash";
import html2canvas from 'html2canvas';


export default function UploadFiles() {
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();
    const [paymentDetails, setPaymentDetails] = useState([])
    const [pdfList, setPdfList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [storedHTML, setStoredHtml] = useState([]);
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [keywords, setKeywords] = useState('')
    const [pageContent, setPageContent] = useState('')
    const [uploadStatus, setUploadStatus] = useState(true)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchPdfs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/accounts/list-pdfs/`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (Array.isArray(data) && data.length > 0) {
                setPdfList(data);
                await loadPreviews(data);
            }
        } catch (error) {
            setError('Failed to load PDFs. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const loadPreviews = async (pdfs) => {
        let pages = [];
        
        for (let pdf of pdfs) {
            if (pdf?.pages) {
                const page = decodeURIComponent(pdf.pages);
                const firstPage = await getPdfPage(1, page, true);
                const previewDiv = document.createElement('div');
                previewDiv.innerHTML = firstPage;
                document.body.appendChild(previewDiv);
                const canvas = await html2canvas(previewDiv);
                const thumbnail = canvas.toDataURL('image/png');
                document.body.removeChild(previewDiv);
                pages.push({
                    'pages': thumbnail,
                    'name': pdf.name,
                    'keywords': pdf.keywords ? JSON.parse(pdf.keywords.replace(/'/g, '"')) : [],
                    'category': pdf.category
                });
            }
        }
        
        setStoredHtml(pages);
    };

    const logoutOption = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        navigate('/');
    };

    const toggleModal = () => {
        setIsModalOpen(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const uploadPDFFileApiResponse = await UploadPDFFileApi(pageContent, name, keywords, category)
        setStoredHtml([])
        fetchPdfs();
        setIsModalOpen(false); 
    };

    useEffect(() => {
        fetchPdfs();
    }, [uploadStatus]);


    return (
        <div>
            <div className="g-sidenav-show bg-gray-200" style={{ height: '100vh' }}>
                <LeftNavBar />
                <div className="main-content position-relative max-height-vh-800 h-100 border-radius-lg">
                    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
                        <div className="container-fluid py-1 px-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                    <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="javascript:;">Pages</a></li>
                                    <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Upload Files</li>
                                </ol>
                                <h6 className="font-weight-bolder mb-0">Upload Files</h6>
                            </nav>
                        </div>
                        <div className='logoutOption'>
                            <h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6>
                        </div>
                    </nav>
                    <div className="container-fluid py-4">
                        <div className="row" style={{ marginBottom: '25px' }} >
                            <div style={{ textAlign: 'end' }}>
                                <button class='upload-file' onClick={toggleModal}>Upload File</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card my-4">
                                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                        <div className="shadow-primary border-radius-lg pt-4 pb-3" style={{ backgroundColor: 'rgb(254, 166, 32)' }}>
                                            <h6 className="text-white text-capitalize ps-3">Upload Files</h6>
                                        </div>
                                    </div>
                                    <div className="card-body px-0 pb-2">
                                        <div className="table-responsive p-0" style={{overflowY: 'hidden'}}>
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"><span>PDF File</span></th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style={{paddingRight: '160px'}}>Name</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style={{paddingRight: '160px'}}>Category</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style={{paddingRight: '160px'}}>Keywords</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    {_.map(storedHTML, (item, index) => {
                                                        return (
                                                            <tr>
                                                                <td style={{textAlign: 'end' }}>
                                                                    <img src={item.pages} alt="Thumbnail" style={{ width: '415px', height: 'auto' }} />
                                                                </td>
                                                                <td style={{ paddingRight: '150px' }} className="align-middle text-center text-sm">
                                                                    <span className="text-secondary text-xs font-weight-bold">{item.name}</span>
                                                                </td>
                                                                <td style={{ paddingRight: '150px' }} className="align-middle text-center">
                                                                    <span className="text-secondary text-xs font-weight-bold">{item.category}</span>
                                                                </td>
                                                                <td style={{ paddingRight: '150px' }} className="align-middle text-center">
                                                                    <ul>
                                                                        {item.keywords.map((keyword, index) => (
                                                                            <li key={index} className="text-secondary text-xs font-weight-bold">
                                                                                {keyword}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content" style={{backgroundColor: 'white', width: '34%', borderRadius: '10px' }} >
                    <button onClick={(e) => setIsModalOpen(false)} className="close-modal">X</button>
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputName" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputName"
                                aria-describedby="emailHelp"
                                required
                                autoComplete="username"
                                style={{ border: '1px solid #d2d6da' }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="categorySelect" className="form-label">Category</label>
                            <select
                                className="form-control"
                                id="categorySelect"
                                required
                                style={{ border: '1px solid #d2d6da', height: 'unset' }}
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="" disabled selected>Select Category</option>
                                <option value="business-cards">Business Cards</option>
                                <option value="pdf-template">PDF Template</option>
                                <option value="frames">Frames</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputKeywords" className="form-label">Keywords</label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputKeywords"
                                required
                                autoComplete="new-password"
                                style={{ border: '1px solid #d2d6da' }}
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="keywordsTextareaPageContent" className="form-label">Page Content</label>
                            <textarea
                                className="form-control"
                                id="keywordsTextareaPageContent"
                                required
                                rows="4"
                                style={{ border: '1px solid #d2d6da' }}
                                value={pageContent}
                                onChange={(e) => setPageContent(e.target.value)}
                            ></textarea>
                        </div>
                        <div style={{ textAlign: 'end' }}>
                            <button type="submit" id="saveNewCreateUser" className="btn btn-primary" >Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
            )}
        </div>
    );
}

