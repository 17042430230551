import React, { useState, useEffect } from 'react';
import { UploadImageAPI, GetImages } from '../ServerApi/server';
import { CONNECTION_REFUSED } from '../helper/Helpers';
import SuccessPopup from '../components/SuccessPopupMessage';
import RefusedPopup from '../components/RefusedPopupMessage';
import { FaCloudUploadAlt } from "react-icons/fa";

export default function MyImages({ setAddElement }) {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const [myImages, setMyImages] = useState([]);

    const fetchImages = async () => {
        try {
            const getImagesApiResponse = await GetImages('upload', localStorage.getItem('userId'));
            if (getImagesApiResponse.status === 200) {
                setMyImages(getImagesApiResponse.data.design_assets);
            } else {
                setPopupMessage(getImagesApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const handleImageUpload = async (e) => {
        const files = e.target.files;
        if (files.length) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('upload_files', files[i]);
            }
            formData.append('user_id', localStorage.getItem('userId'));
            formData.append('asset_type', 'upload');
            try {
                const uploadImageFileResponse = await UploadImageAPI(formData);
                if (uploadImageFileResponse.status === 200) {
                    fetchImages();
                } else {
                    setPopupMessage(uploadImageFileResponse.response.data.message);
                    setShowPopup(true);
                    setTimeout(() => setShowPopup(false), 2000);
                }
            } catch (error) {
                setPopupRefusedMessage(CONNECTION_REFUSED);
                setShowPopupRefused(true);
                setTimeout(() => setShowPopupRefused(false), 2000);
            }
        }
    };

    return (
        <div className='w-full'>
            <div className='w-full h-[45px] flex justify-center items-center bg-[#7731d8] rounded-md hover:bg-[#612dae] text-white mb-3'>
                <label className='text-[15px] font-[500] cursor-pointer w-full h-full flex justify-center items-center gap-2 text-white mt-2' htmlFor="fileUpload">
                    <FaCloudUploadAlt className='text-[19px] font-[500]' /> upload image
                </label>
                <input
                    type='file'
                    id='fileUpload'
                    accept="image/*"
                    className='hidden'
                    multiple
                    onChange={handleImageUpload}
                />
            </div>

            <div className='w-full'>
                <div
                    className='w-full h-auto flex gap-x-1 gap-y-4 flex-wrap justify-between bg-[rgba(0,0,0,0.05)] p-1 rounded-md'
                >
                    {myImages.map((img, i) => {
                        return (
                            <img
                                key={i}
                                className='w-[48%] h-[130px] object-cover rounded-md cursor-pointer bg-white p-2'
                                src={`data:image/png;base64,${img.file_base64}`}
                                onClick={() => setAddElement('image', `data:image/png;base64,${img.file_base64}`)}
                                alt={img.name}
                            />
                        );
                    })}
                </div>
            </div>

            {showPopup && (
                <SuccessPopup popupMessage={popupMessage} />
            )}
            {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
            )}
        </div>
    );
}
