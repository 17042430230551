import React from 'react'
import LeftNavBar from './LeftNavBar'
import { useNavigate } from 'react-router-dom'

export default function Schools() {

    const navigate = useNavigate();

    const logoutOption = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('role')
        navigate('/')
    }

    return (
        <div class="g-sidenav-show  bg-gray-200" style={{height: '100vh'}}>
            <LeftNavBar />
            <div class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
                    <div class="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
                                <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Schools</li>
                            </ol>
                            <h6 class="font-weight-bolder mb-0">Schools</h6>
                        </nav>
                        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

                            <ul class="navbar-nav  justify-content-end">

                                <li class="nav-item d-flex align-items-center">
                                    <a href="pages/sign-in.html" class="nav-link text-body font-weight-bold px-0">
                                        <i class="fa fa-user me-sm-1"></i>
                                        <span class="d-sm-inline d-none">Sign In</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoutOption'><h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6></div>
                </nav>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
                            <div class="card card-plain">
                                <div class="card-header">
                                    <h4 class="font-weight-bolder">Add School</h4>
                                </div>
                                <div class="card-body">
                                    <form role="form">
                                        <label class="form-label">School Name</label>
                                        <div class="input-group input-group-outline mb-3">

                                            <input type="text" class="form-control" />
                                        </div>

                                        <label class="form-label">Phone</label>
                                        <div class="input-group input-group-outline mb-3">

                                            <input type="number" class="form-control" />
                                        </div>
                                        <label class="form-label">Email</label>
                                        <div class="input-group input-group-outline mb-3">

                                            <input type="email" class="form-control" />
                                        </div>

                                        <label class="form-label">Address</label>
                                        <div class="input-group input-group-outline mb-3">

                                            <textarea class="form-control"></textarea>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="text-center">
                                                    <button type="button" id="schoolSave" class="btn btn-lg btn-lg w-100 mt-4 mb-0">Save</button>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
                                                <div class="text-center">
                                                    <button type="button" id="schoolSaveAnother" class="btn btn-lg btn-lg w-100 mt-4 mb-0">Save and Add Another</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}