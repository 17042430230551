import React, { useEffect, useState } from 'react';
import _ from "lodash";
import * as pdfjsLib from 'pdfjs-dist';
import { getPdfPage } from '../helper/Helpers';
import { getLatestEditedPdf } from '../ServerApi/server';

pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.mjs`;

export default function PdfSidebar({ showPdfUrl, component }) {

    const [pdfList, setPdfList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [storedHTML, setStoredHtml] = useState([]);

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await getLatestEditedPdf();
                const data = response.edited_pdfs || [];
                if (data?.length) {
                    setPdfList(data);
                    await loadPreviews(data);
                }
            } catch (error) {
                setError('Failed to load PDFs. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchPdfs();
    }, []);

    const loadPreviews = async (pdfs) => {
        if (!storedHTML?.length) {
            let pages = [];
            for (let pdf of pdfs) {
                if (pdf?.embedded_pages) {
                    const parsedPage = decodeURIComponent(pdf.embedded_pages);
                    const firstPage = await getPdfPage(1, parsedPage, true);
                    pages.push(firstPage);
                }
            }
            setStoredHtml(pages);
        }
    };

    return (
        <React.Fragment>
            {loading ? (
                <div className="loader">Loading PDFs...</div>
            ) : error ? (
                <div className="error">{error}</div>
            ) : pdfList.length > 0 ? (
                <ul className="flex justify-between w-full p-0 flex-wrap gap-y-2">
                    {_.map(storedHTML, (item, index) => {
                        return (
                            <li
                                key={index}
                                className='relative w-[46%] max-w-[46%] h-[150px] max-h-[150px] rounded-md overflow-hidden p-2 cursor-pointer'
                                onClick={() => {
                                    showPdfUrl({ id: pdfList[index].pdf_id, pages: decodeURIComponent(pdfList[index].embedded_pages) });
                                    const element = document.querySelectorAll('[isLandscape="true"]');
                                    if (element?.length) {
                                        _.forEach(element, item => {
                                            item.removeAttribute('isLandscape');
                                        })
                                    }
                                }}
                            >
                                <div className='absolute -top-[49px] -left-[45px] p-1 preview-Container w-[150px] h-[150px] scale-[0.27] border-[1px] border-solid' style={{ pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: item }} ></div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p className="no-pdfs-message text-center">No PDFs found.</p>
            )}
        </React.Fragment>
    );
}
