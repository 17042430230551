import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import LoGoImage from '../Login/Images/logo.png';
import { IoMdPrint } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AiOutlineLogout } from "react-icons/ai";
import { FaCloudDownloadAlt } from "react-icons/fa";

export default function Header() {

    const navigate = useNavigate();
    const [cardName, setCardName] = useState('');

    const printImage = async () => {

        // reset the pdf focused element
        const focusedElement = document.querySelectorAll('[data-id="true"]');
        if (focusedElement?.length) {
            _.forEach(focusedElement, item => {
                item.style.border = '';
                item.removeAttribute('data-id');
            })
        }

        const isLandscape = sessionStorage.getItem('isLandscape') === "true";
        const sections = document.querySelectorAll('.previewArea');
        const pdfContent = document.createElement('div');
        let width = 0;
        let height = 0;

        const dpi = 300;
        const scale = dpi / 96;

        const imgDataArray = [];

        for (const section of sections) {
            const canvas = await html2canvas(section, { logging: true, scale: scale, useCORS: true });
            const imgData = canvas.toDataURL('image/png', 1.0);

            imgDataArray.push(imgData);

            width = canvas.width;
            height = canvas.height;

            const imgElement = document.createElement('img');
            imgElement.src = imgData;

            pdfContent.appendChild(imgElement);
        }

        document.body.appendChild(pdfContent);

        if (width && height) {
            const pdf = new jsPDF(isLandscape ? 'landscape' : 'portrait', 'px', [width, height]);

            for (let i = 0; i < imgDataArray.length; i++) {
                if (i > 0) {
                    pdf.addPage();
                }
                pdf.addImage(imgDataArray[i], 'PNG', 0, 0, width, height);
            }

            await pdf.save(cardName ? `${cardName}.pdf` : 'businesscard.pdf');
        }

        document.body.removeChild(pdfContent);
    };

    const handleOrderAndPrint = () => {
        const sections = document.querySelectorAll('.previewArea');
        const pageCount = sections.length;
        const pages = Array.from(sections).map(section => section.innerHTML);
        if (pageCount && pageCount > 0) {
            const firstSection = sections[0];

            html2canvas(firstSection).then(canvas => {
                const thumbnail = canvas.toDataURL('image/png');

                navigate('/Addcarts', { state: { pageCount, pages, thumbnail } });
            });
        } else {
            alert("Page count is required to add to cart.");
        }
    };

    return (
        <div className='fixed h-[60px] px-3 py-1 w-full z-50 bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8]' >
            <div className='absolute left-0 top-0 w-[150px] h-[120%] bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8] flex justify-center items-center cursor-pointer' style={{ borderBottomRightRadius: '10px', borderRight: '2px solid white', borderBottom: '2px solid white' }} >
                <img src={LoGoImage} className='aspect-square w-[100px] h-[50px] -mt-[4px]' alt='pixel-logo' />
            </div>
            <div className='absolute left-[180px] top-0 h-full flex justify-center items-center gap-3'>
                <input
                    type="text"
                    className='w-[350px] h-[40px] px-2 outline-none rounded-md text-white placeholder-white font-[500] text-[15px] bg-transparent border-[1.5px] border-solid border-[rgba(255,255,255,0.6)]'
                    placeholder='business card.pdf'
                    spellCheck={false}
                    value={cardName || 'pure pixel card.pdf'}
                    onChange={(e) => setCardName(e.target.value)}
                    readOnly={true}
                />
                <div className='autosave-icon-container'>
                    <Tippy content={<span className='text-[12px]'>Auto save</span>}>
                        <FaCloudDownloadAlt id='autosave-icon' className='text-white text-[31px] p-1 rounded-full cursor-pointer' />
                    </Tippy>
                </div>
            </div>
            <div className='flex justify-between items-center text-gray-300 h-full'>
                <Link to='/design/edit'>
                    {/* <img src={LoGoImage} className='aspect-square w-[100px] h-[50px] -mt-[4px]' alt='pixel-logo' /> */}
                </Link>
                <div className='flex gap-2'>
                    <div className='flex justify-center items-center gap-2 text-gray-300'>
                        <Tippy content={<span className='text-[12px]'>Order & Print</span>}>
                            <button onClick={handleOrderAndPrint} className='px-3 py-[6px] outline-none bg-transparent text-white font-[500] rounded-sm flex gap-1 items-center text-[15px]' style={{ border: '1px solid rgba(255,255,255,0.3)' }}>Order & Print <IoMdPrint className='text-[16px]' /></button>
                        </Tippy>
                    </div>
                    <div className='flex justify-center items-center gap-2 relative group'>
                        <Tippy content={<span className='text-[12px]'>Share your work</span>}>
                            <button className='px-3 py-[6px] outline-none bg-white rounded-md text-[#333] font-[500] flex gap-1 items-center text-[15px]' style={{ border: '1px solid rgba(255,255,255,0.3)' }}>
                                <MdOutlineFileUpload className='text-[16px]' />
                                Share
                            </button>
                        </Tippy>
                        <Tippy content={<span className='text-[12px]'>Logout</span>}>
                            <button className='px-3 py-[6px] outline-none bg-white rounded-md text-[#333] font-[500] flex gap-1 items-center text-[15px]' style={{ border: '1px solid rgba(255,255,255,0.3)' }} onClick={() => { localStorage.removeItem('userId'); localStorage.removeItem('role'); navigate('/') }} >
                                <AiOutlineLogout className='text-[16px]' />
                                Logout
                            </button>
                        </Tippy>
                    </div>
                </div>
            </div>
        </div>
    )
};