import React from 'react';

export default function Image({ myImages, setAddElement }) {

    return (
        <div className='w-full'>
            <p className='border-[1px] border-solid py-[7px] px-1 w-full shadow-sm bg-[#992bff] text-white' style={{ clipPath: "polygon(0 1%, 100% 0%, 91% 100%, 0% 100%)" }}>Select Your Template Images</p>
            <div className='w-full'>
                <div
                    className='w-full h-auto flex gap-x-1 gap-y-4 flex-wrap justify-between bg-[rgba(0,0,0,0.05)] p-1 rounded-md'
                >
                    {myImages.map((img, i) => {
                        return (
                            <img
                                key={i}
                                className='w-[48%] h-[130px] object-cover rounded-md cursor-pointer bg-white p-2'
                                src={`data:image/png;base64,${img.file_base64}`}
                                onClick={() => setAddElement('image', `data:image/png;base64,${img.file_base64}`)}
                                alt={img.name}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
