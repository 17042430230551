import React from 'react';

export default function Background({ myImages, setAddElement }) {
    return (
        <div className='w-full'>
            <p className='border-[1px] border-solid py-[7px] px-1 w-full shadow-sm bg-[#333] text-white' style={{ clipPath: "polygon(0 1%, 100% 0%, 91% 100%, 0% 100%)" }}>Select Your Background Images</p>
            <div className="grid grid-cols-2 gap-2 bg-[rgba(0,0,0,0.05)] p-1 rounded-md">
                {myImages.map((img, index) => (
                    <div
                        key={index}
                        className="w-full h-[100px] overflow-hidden rounded-sm cursor-pointer transition-colors bg-white p-2"
                    >
                        <img
                            className="w-full h-full object-fill rounded-md"
                            src={`data:image/png;base64,${img.file_base64}`}
                            onClick={() => setAddElement('image', `data:image/png;base64,${img.file_base64}`)}
                            alt={img.name}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
