import React, {useState, useRef} from 'react'
import LeftNavBar from './LeftNavBar'
import { useNavigate } from 'react-router-dom'
import { UploadImageAPI } from '../ServerApi/server'
import Cookies from 'js-cookie';
import { CONNECTION_REFUSED } from '../helper/Helpers';
import RefusedPopup from '../components/RefusedPopupMessage';
import SuccessPopup from '../components/SuccessPopupMessage'


export default function DesignAssets() {
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [assetType, setAssetType] = useState('png');
    const fileInputRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const logoutOption = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('role')
        navigate('/')
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const addDesignAsset = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('asset_type', assetType);
        formData.append('upload_files', image);

        try {
            const designAssetsResponse = await UploadImageAPI(formData)
            console.log('designAssetsResponse', designAssetsResponse)
            setImage(null);
            setName('');
            setAssetType('png');
            fileInputRef.current.value = ''
            setPopupMessage(designAssetsResponse.data.message);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 2000);
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        }
    };

    return (
        <div class="g-sidenav-show bg-gray-200" style={{ height: '100vh' }}>
            <LeftNavBar />
            <div class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
                    <div class="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
                                <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Design Assets</li>
                            </ol>
                            <h6 class="font-weight-bolder mb-0">Design Assets</h6>
                        </nav>
                        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

                            <ul class="navbar-nav  justify-content-end">

                                <li class="nav-item d-flex align-items-center">
                                    <a href="pages/sign-in.html" class="nav-link text-body font-weight-bold px-0">
                                        <i class="fa fa-user me-sm-1"></i>
                                        <span class="d-sm-inline d-none">Sign In</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoutOption'><h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6></div>
                </nav>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
                            <div class="card card-plain">
                                <div class="card-header">
                                    <h4 class="font-weight-bolder">Add Design Asset</h4>
                                </div>
                                <div class="card-body">
                                    <form role="form" onSubmit={addDesignAsset}>
                                        <label class="form-label">Name</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <input type="text" class="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter the asset name" required />
                                        </div>
                                        <label class="form-label">File</label>
                                        <div class="input-group input-group-outline mb-3">

                                            <input type="file" ref={fileInputRef} onChange={handleImageChange} required class="form-control" />
                                        </div>
                                        <label class="form-label">Asset Type</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <select class="form-control" value={assetType} onChange={(e) => setAssetType(e.target.value)} required>
                                                <option>--Select--</option>
                                                <option value='png'>PNG Images</option>
                                                <option value='svg'>SVG Images</option>
                                                <option value='background'>Background Images</option>
                                            </select>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="text-center">
                                                    <button type="submit" id="designAssetsSave" class="btn btn-lg btn-lg w-100 mt-4 mb-0">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <SuccessPopup popupMessage={popupMessage} />
            )}
            {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
            )}
        </div>
    )
}