import React, { useState, useEffect } from 'react'
import LeftNavBar from './LeftNavBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaCartArrowDown } from "react-icons/fa";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { AddToCartAPI } from '../ServerApi/server';


export default function AddCart() {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [quantity, setQuantity] = useState(1);
    const location = useLocation();
    const { pageCount, pages, thumbnail } = location.state || {};
    const pricePerPage = 5;
    const mainPrice = pricePerPage * pageCount * quantity;
    const taxRate = 8.87;
    const taxAmount = (mainPrice * taxRate) / 100;
    const totalCost = mainPrice + taxAmount;
    const formattedTotalCost = totalCost.toFixed(2);

    const increaseQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const addToCart = async () => {
        const cartData = {
            quantity,
            amount: mainPrice.toFixed(2),
            total_amount: formattedTotalCost,
            user: localStorage.getItem('userId'),
            pages
        };

        try {
            const response = await AddToCartAPI(cartData);
            console.log('Cart added or updated:', response.data);
        } catch (error) {
            console.error('Error updating cart:', error.response?.data);
        }
    };

    useEffect(() => {
        if (location.state) {
            addToCart();
        }
    }, [quantity, location.state]);


    const formatDateTime = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleDateString('en-US', options);
    };

    const logoutOption = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('iroled')
        navigate('/')
    }

    return (
        <div class="g-sidenav-show  bg-gray-200" style={{ height: '100vh' }}>
            <LeftNavBar />
            <div class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
                    <div class="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
                                <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Cart</li>
                            </ol>
                            <h6 class="font-weight-bolder mb-0">Cart</h6>
                        </nav>
                        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

                            <ul class="navbar-nav  justify-content-end">

                                <li class="nav-item d-flex align-items-center">
                                    <a href="pages/sign-in.html" class="nav-link text-body font-weight-bold px-0">
                                        <i class="fa fa-user me-sm-1"></i>
                                        <span class="d-sm-inline d-none">Sign In</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoutOption'><h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6></div>
                </nav>

                <div class="container-fluid py-4">

                    <div class="row">

                        <div class="col-md-7 mt-4">
                            <div class="card">
                                <div class="card-header pb-0 px-3">
                                    <h6 class="mb-0">Yearbook Information</h6>
                                </div>
                                <div class="card-body pt-4 p-3">
                                    <ul class="list-group">
                                        <li style={{ justifyContent: "space-between" }} class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg" >
                                            <div>
                                                {thumbnail && <img src={thumbnail} alt="Thumbnail" style={{ width: '100px', height: 'auto', borderRadius: '5px' }} />}
                                            </div>
                                            <div class="d-flex flex-column">

                                                <h6 class="mb-3 text-sm"></h6>

                                                <span class="mb-2 text-xs">Description: <span class="text-dark font-weight-bold ms-sm-2">Yearbook</span></span>
                                                <span class="mb-2 text-xs" style={{ display: 'flex' }}><span style={{ marginTop: '3px' }} >Quantity: </span><span class="text-dark ms-sm-2 font-weight-bold" style={{ display: 'flex' }}>
                                                    <CiSquareMinus style={{ fontSize: '22px', cursor: 'pointer' }} onClick={decreaseQuantity} />&nbsp;<span style={{ marginTop: '3px' }}>{quantity}</span>&nbsp;<CiSquarePlus style={{ fontSize: '22px', cursor: 'pointer' }} onClick={increaseQuantity} /></span></span>
                                                <span class="mb-2 text-xs">Price: <span class="text-dark ms-sm-2 font-weight-bold">$ {mainPrice}</span></span>

                                            </div>

                                        </li>

                                    </ul>

                                    <div class="text-center">
                                        <button type="button" id='submitEmailButton' class="btn w-100 my-4 mb-2">Pay $ {formattedTotalCost}</button>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-md-5 mt-4">
                            <div class="card h-100 mb-4">
                                <div class="card-header pb-0 px-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h6 class="mb-0" style={{ display: 'flex' }}>Cart Summary<FaCartArrowDown style={{ marginLeft: '8px', fontSize: '25px' }} /></h6>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-body pt-4 p-3">

                                    <ul class="list-group">

                                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div class="d-flex align-items-center">

                                                <div class="d-flex flex-column">
                                                    <h6 class="mb-1 text-dark text-sm">Yearbook Cost</h6>
                                                    <span class="text-xs">{formatDateTime(currentTime)}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                $ {mainPrice}
                                            </div>
                                        </li>
                                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div class="d-flex align-items-center">

                                                <div class="d-flex flex-column">
                                                    <h6 class="mb-1 text-dark text-sm">NY Sales Tax</h6>
                                                    <span class="text-xs">{formatDateTime(currentTime)}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                $ {taxAmount.toFixed(2)} ({taxRate}%)
                                            </div>
                                        </li>
                                        <hr style={{ background: "#000" }} />
                                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div class="d-flex align-items-center">

                                                <div class="d-flex flex-column">
                                                    <h6 class="mb-1 text-dark text-sm">Total</h6>
                                                    <span class="text-xs">{formatDateTime(currentTime)}</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                $ {formattedTotalCost}
                                            </div>
                                        </li>
                                        <hr style={{ background: "#000" }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}