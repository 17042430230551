import React, { useState } from 'react';
import Frame from "../assets/img/frame.webp";
import Element from "../assets/img/editor.webp";

export default function Elements({ myImages, setAddElement }) {
    const [isOpen, setIsOpen] = useState('frames');

    const toggleAccordion = (section) => {
        setIsOpen((prevSection) => (prevSection === section ? '' : section));
    };

    return (
        <div className="w-full h-full space-y-4 overflow-y-auto">
            <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                <button
                    className="w-full text-left py-3 px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-blue-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                    onClick={() => toggleAccordion('frames')}
                >
                    <span className="text-md text-[rgba(0,0,0,0.7)]">Frames</span>
                    <span className="text-[rgba(0,0,0,0.7)]">
                        <img src={Frame} alt="frame-icon" className='w-[30px]' />
                    </span>
                </button>
                {isOpen === 'frames' && (
                    <div className="p-1 bg-blue-50 text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                        <div className="bg-white p-2 shadow rounded cursor-pointer">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNVAyBoVOAojI65DU5Mw4uxkmiazNGsR_0bg&s" alt="Frame 1" className="w-32 h-32 object-cover rounded" />
                        </div>
                        <div className="bg-white p-2 shadow rounded cursor-pointer">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNVAyBoVOAojI65DU5Mw4uxkmiazNGsR_0bg&s" alt="Frame 2" className="w-32 h-32 object-cover rounded" />
                        </div>
                        <div className="bg-white p-2 shadow rounded cursor-pointer">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNVAyBoVOAojI65DU5Mw4uxkmiazNGsR_0bg&s" alt="Frame 3" className="w-32 h-32 object-cover rounded" />
                        </div>
                        <div className="bg-white p-2 shadow rounded cursor-pointer">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNVAyBoVOAojI65DU5Mw4uxkmiazNGsR_0bg&s" alt="Frame 4" className="w-32 h-32 object-cover rounded" />
                        </div>
                    </div>
                )}
            </div>

            {/* Elements Accordion */}
            <div className="w-full border rounded-lg shadow-lg overflow-hidden">
                <button
                    className="w-full text-left py-3 px-3 font-semibold text-gray-800 flex justify-between items-center hover:bg-green-100 transition-all duration-200 ease-in-out sticky top-0 bg-white z-10"
                    onClick={() => toggleAccordion('elements')}
                >
                    <span className="text-md text-[rgba(0,0,0,0.7)]">Elements</span>
                    <span className="text-[rgba(0,0,0,0.7)]">
                        <img src={Element} alt="elements" className='w-[30px] h-[20px]' />
                    </span>
                </button>
                {isOpen === 'elements' && (
                    <div className="p-1 bg-[rgba(0,0,0,0.05)] text-gray-700 transition-all duration-300 ease-in-out grid grid-cols-2 gap-1">
                        {myImages.map((img, i) => {
                            return (
                                <div className="bg-white p-2 shadow rounded cursor-pointer">
                                    <img
                                        key={i}
                                        className="w-32 h-32 object-cover rounded"
                                        src={`data:image/png;base64,${img.file_base64}`}
                                        onClick={() => setAddElement('image', `data:image/png;base64,${img.file_base64}`)}
                                        alt={img.name}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
