import React, { useState, useEffect, useRef } from 'react';
import { getPdfPage } from '../helper/Helpers';
import _ from 'lodash';
import { saveEditedHTML } from '../ServerApi/server';

export default function CreateComponent({ info, setCurrentComponent, pdfId, pdfPages, pageIndex, getElementStyles, setElementStyles, setSelectedElement, layoutController, selectedElement, drawState, isSectionActivated, reset, domUpdated }) {

    const [isDrawActivated, setIsDrawActivated] = useState(false);
    var html = '';
    const isFirstRender = useRef(true);

    const handleMainFrame = (info) => {
        setTimeout(() => {
            const isElementFocused = document.querySelector('[data-id="true"]');
            if (info.name === 'main_frame' && isElementFocused) {
                setCurrentComponent(info);
            }
        }, 100);
    };

    useEffect(() => {

        const renderPage = async () => {
            const pageElement = document.querySelector(`.pdfPage-${pageIndex}`);
            if (pageElement) {
                const page = await getPdfPage(pageIndex, pdfPages);
                pageElement.innerHTML = page;
            }
        };

        if (pdfPages && pageIndex) {
            renderPage();
        }
    }, [pdfPages, pageIndex]);

    useEffect(() => {
        if (selectedElement && setElementStyles) {

            selectedElement.style.display = 'flex';
            if (selectedElement.tagName === "IMG") {
                selectedElement.style.width = `${setElementStyles?.width}px`;
                selectedElement.style.height = `${setElementStyles?.height}px`;
            }
            if (setElementStyles?.rotate) {
                selectedElement.style.transform = `rotate(${setElementStyles?.rotate}deg)`;
            }
            selectedElement.style.opacity = setElementStyles?.opacity;

            if (setElementStyles?.fontSize) {
                const fontSize = setElementStyles?.fontSize;
                selectedElement.style.width = 'auto';
                selectedElement.style.height = 'auto';
                selectedElement.style.fontSize = `${fontSize}px`;
                if (selectedElement.tagName === "DIV") {
                    const actualWidth = selectedElement.scrollWidth;
                    const actualHeight = selectedElement.scrollHeight;

                    selectedElement.style.width = `${actualWidth}px`;
                    selectedElement.style.height = `${actualHeight}px`;
                }
            }

            selectedElement.style.fontFamily = setElementStyles.fontFamily;
            selectedElement.style.background = setElementStyles.backgroundColor;
            selectedElement.style.color = setElementStyles.color;
            selectedElement.style.fontWeight = setElementStyles.bold;
            selectedElement.style.fontStyle = setElementStyles.italic ? `italic` : 'normal';
            selectedElement.style.textDecoration = setElementStyles.underline ? `underline` : 'none';
            selectedElement.style.justifyContent = setElementStyles.left ? 'flex-start' : setElementStyles.right ? 'flex-end' : setElementStyles.middle ? 'center' : 'default';
            selectedElement.style.alignItems = setElementStyles.top ? 'flex-start' : setElementStyles.bottom ? 'flex-end' : setElementStyles.middleVertical ? 'center' : 'default';
            if (selectedElement.tagName === "IMG") {
                selectedElement.style.filter =
                    ` 
                ${setElementStyles?.brightness ? `brightness(${setElementStyles.brightness}%)` : 'brightness(100%)'}
                ${setElementStyles?.contrast ? `contrast(${setElementStyles.contrast}%)` : 'contrast(100%)'}
                ${setElementStyles?.grayscale ? `grayscale(${setElementStyles.grayscale}%)` : `grayscale(0%)`}
                ${setElementStyles?.invert ? `invert(${setElementStyles.invert}%)` : `invert(0%)`}
                ${setElementStyles?.saturate ? `saturate(${setElementStyles.saturate})` : `saturate(1)`}
                ${setElementStyles?.sepia ? `sepia(${setElementStyles.sepia}%)` : `sepia(0%)`}
                ${setElementStyles?.blur ? `blur(${setElementStyles.blur}px)` : 'blur(0)'}
                `.trim();
            }
        }
    }, [selectedElement, setElementStyles]);

    let currentElement = null;
    let isDragging = false;
    let isClicked = true;
    let isElementActive = false;

    const handlePdfPageController = (e) => {
        if (isDrawActivated) return;
        const element = e.target;
        const RootElement = document.getElementById('pdfContainer');
        // const ElementParent = element.parentNode;
        // if (element && !element.className.includes('page') && (ElementParent.className === 'image' || ElementParent.className === 'vector' || element.innerText.trim() !== "")) {
        if (element && !element.id.includes("pdfContainer") && !element.className.includes('page')) {
            if (e.type === 'mouseover' && !isElementActive) {
                element.style.cursor = 'all-scroll';
                element.style.border = `2px solid rgb(139 61 255)`;

                const findRootElements = RootElement.querySelectorAll('span, .vector, img, canvas, div');
                const borderedSpans = Array.from(findRootElements).filter(span =>
                    span.style.border !== '' && !span.hasAttribute('data-id')
                );

                if (borderedSpans.length > 1) {
                    borderedSpans.forEach(span => {
                        span.style.border = '';
                    });
                }

            }

            if (e.type === "click" && !isElementActive) {
                const findRootElements = RootElement.querySelectorAll('span, .vector, img, canvas, p, h3, h5, div');
                const borderedSpans = Array.from(findRootElements).filter(span => span.style.border !== '');
                const editableElements = Array.from(findRootElements).filter(item => item.contentEditable === "true");

                if (borderedSpans.length > 1) {
                    borderedSpans.forEach(span => {
                        span.style.border = '';
                    });
                }

                if (editableElements.length > 1) {
                    editableElements.forEach(item => {
                        item.contentEditable = "false";
                    });
                }

                const dataAttribute = RootElement.querySelectorAll('[data-id="true"]');

                if (dataAttribute?.length) {
                    _.forEach(dataAttribute, element => {
                        element.removeAttribute('data-id');
                    })
                }

                isClicked = true;
                element.style.outline = 'none';
                element.style.borderRadius = '5px';
                element.style.padding = '5px';
                element.setAttribute('data-id', true);

                setSelectedElement(null);
                setSelectedElement(element);

                // parse selected element styles
                const width = getComputedStyle(element, null).getPropertyValue('width').replace('px', '');
                const height = getComputedStyle(element, null).getPropertyValue('height').replace('px', '');
                const transform = getComputedStyle(element, null).getPropertyValue('transform');
                const opacity = getComputedStyle(element, null).getPropertyValue('opacity');
                const fontSize = getComputedStyle(element, null).getPropertyValue('fontSize');
                const fontFamily = getComputedStyle(element, null).getPropertyValue('font-family');
                const backgroundColor = getComputedStyle(element, null).getPropertyValue('background-color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const color = getComputedStyle(element, null).getPropertyValue('color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const bold = getComputedStyle(element, null).getPropertyValue('font-weight');
                const blur = parseFloat(getComputedStyle(element).filter.match(/blur\((\d+(\.\d+)?)px\)/)?.[1]) || 0;
                const brightness = (parseFloat(getComputedStyle(element).filter.match(/brightness\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 100;
                const contrast = (parseFloat(getComputedStyle(element).filter.match(/contrast\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 100;
                const grayscale = (parseFloat(getComputedStyle(element).filter.match(/grayscale\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;
                const invert = (parseFloat(getComputedStyle(element).filter.match(/invert\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;
                const saturate = parseFloat(getComputedStyle(element).filter.match(/saturate\((\d+(\.\d+)?)\)/)?.[1]) || 1;
                const sepia = (parseFloat(getComputedStyle(element).filter.match(/sepia\((\d+(\.\d+)?)\)/)?.[1]) * 100) || 0;

                let rotate = 0;
                if (transform !== 'none') {
                    const values = transform.split('(')[1].split(')')[0].split(',');
                    const a = values[0];
                    const b = values[1];
                    rotate = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                }

                const data = { width, height, rotate, opacity, fontSize, fontFamily, backgroundColor: `#${backgroundColor}`, color: `#${color}`, bold, blur, brightness, contrast, grayscale, invert, saturate, sepia };
                getElementStyles(data);

                if (element.innerText.trim() !== "") {
                    element.setAttribute("contentEditable", "true");
                    element.setAttribute("spellcheck", "false");
                    element.style.cursor = "text";
                    element.focus();
                }
            }

            if (e.type === 'mouseout' && !isElementActive) {
                if (!isClicked) {
                    if (element.hasAttribute('data-id')) {
                        setCurrentComponent('');
                        element.removeAttribute('data-id');
                    }
                } else {
                    isClicked = false;
                }
                const isFocused = element.getAttribute("data-id");
                if (!isFocused) element.style.border = '';
                element.style.padding = '0';
                element.style.borderRadius = '0';
                element.style.cursor = 'default';
            }

            if (e.type === 'mousedown') {
                e.preventDefault();
                isDragging = true;
                isElementActive = true;
                currentElement = element;
                currentElement.style.cursor = 'all-scroll';
            }

        }

        if (currentElement && e.type === 'mouseup') {
            isDragging = false;
            if (currentElement) {
                currentElement.style.cursor = 'all-scroll';
            }
            currentElement = null;
            isElementActive = false;
        }

    };

    const handleMouseMove = (e) => {
        if (isDrawActivated) return;
        if (isDragging && currentElement) {
            const parentNode = currentElement.parentNode;
            if (currentElement.tagName === "IMG" && !parentNode.className.includes('previewArea')) {
                let elementWidth = null, elementHeight = null;
                // if (parentNode) {
                //     parentNode.removeAttribute('style');
                //     currentElement = parentNode;
                //     elementWidth = getComputedStyle(currentElement, null).getPropertyValue('width');
                //     elementHeight = getComputedStyle(currentElement, null).getPropertyValue('height');
                // }
                // currentElement.style.position = 'absolute';
                currentElement.style.width = elementWidth;
                currentElement.style.height = elementHeight;
            }

            const targetElement = e.currentTarget;
            const parentElement = targetElement.parentNode;

            const totalWidth = parentElement.offsetWidth;
            const totalHeight = parentElement.offsetHeight;

            const mouseX = e.clientX - parentElement.getBoundingClientRect().left;
            const mouseY = e.clientY - parentElement.getBoundingClientRect().top;

            const mouseXPercentage = (mouseX / totalWidth) * 100;
            const mouseYPercentage = (mouseY / totalHeight) * 100;

            let topPosition = 10;
            if (parentNode.id === 'pdfContainer') topPosition = 4;
            currentElement.style.left = `${mouseXPercentage}%`;
            currentElement.style.top = `${mouseYPercentage - topPosition}%`;

        }
    };

    useEffect(() => {
        const pdfObserver = new MutationObserver(async (mutationsList) => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }

            const now = Date.now();
            const THROTTLE_INTERVAL = 2000;

            if (now - pdfObserver.lastExecuted < THROTTLE_INTERVAL) return;
            pdfObserver.lastExecuted = now;

            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    const workSpace = document.querySelectorAll('.previewArea');

                    let pages = [];

                    if (workSpace?.length) {
                        workSpace.forEach(item => {
                            const previewSpace = item;

                            if (previewSpace) {
                                pdfObserver.disconnect();

                                const tempHTML = previewSpace.cloneNode(true);

                                let outerHTML = previewSpace.cloneNode(true);
                                const elementsToRemove = outerHTML.querySelectorAll('[id*="pf"]');
                                elementsToRemove.forEach(element => element.remove());

                                const modifiedInnerHTML = outerHTML.innerHTML;

                                const pfContainer = tempHTML.querySelector('[id*="pf"]');
                                if (pfContainer) {
                                    pfContainer.insertAdjacentHTML('beforeend', modifiedInnerHTML);
                                }

                                const width = tempHTML.style.width;
                                const height = tempHTML.style.height;

                                tempHTML.removeAttribute('style');
                                tempHTML.style.width = width;
                                tempHTML.style.height = height;

                                // reset the pdf focused element
                                const focusedElement = tempHTML.querySelectorAll('[data-id="true"]');
                                if (focusedElement?.length) {
                                    _.forEach(focusedElement, item => {
                                        item.style.border = '';
                                        item.removeAttribute('data-id');
                                    })
                                }

                                const elementsWithCursor = tempHTML.querySelectorAll('[style*="cursor"]');

                                elementsWithCursor.forEach(element => {
                                    element.style.cursor = 'pointer';
                                });

                                const JSX = `${tempHTML.outerHTML}`;
                                pages.push(JSX);

                                document.querySelectorAll('.previewArea').forEach(element => {
                                    pdfObserver.observe(element, {
                                        attributes: true,
                                        childList: true,
                                        subtree: true
                                    });
                                });
                            }
                        });

                        const pdfChanges = `
                          <!DOCTYPE html>
                          <html>
                          <head>
                              <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
                              <style>
                              ${document.getElementById('pdf-page-styles')?.innerHTML}
                              </style>
                              <script>
                               try{
                                  pdf2htmlEX.defaultViewer = new pdf2htmlEX.Viewer({});
                               }catch(e){}
                              </script>
                          </head>
                          <body style="color:#000000;">
                              ${pages.join('')}
                          </body>
                          </html>
                        `;
                        const user_id = localStorage.getItem('userId');
                        const email = localStorage.getItem('email');
                        const payload = { pdf_id: pdfId, title_name: 'businessCard', embedded_pages: encodeURIComponent(pdfChanges), user_id, email };
                        if (pdfId && user_id && email && pdfChanges) {
                            await saveEditedHTML(payload);
                            const autosave = document.getElementById('autosave-icon');
                            if (autosave) {
                                autosave.classList.add('animate-pulse');
                                autosave.classList.add('glow-animation');
                                setTimeout(() => {
                                    autosave.classList.remove('animate-pulse');
                                    autosave.classList.remove('glow-animation');
                                }, 1000);
                            }
                        }
                    }
                }
            }
        });

        pdfObserver.lastExecuted = 0;

        document.querySelectorAll('.previewArea').forEach(element => {
            pdfObserver.observe(element, {
                attributes: true,
                childList: true,
                subtree: true
            });
        });

        return () => {
            pdfObserver.disconnect();
        };

    }, []);

    useEffect(() => {
        const isDrawActive = sessionStorage.getItem('currentPreview');
        if (drawState && isDrawActive) {
            setIsDrawActivated(true);
            const images = document.querySelectorAll('#pdfContainer img');
            if (images.length) {
                _.forEach(images, element => {
                    element.style.pointerEvents = 'none';
                })
            }
        } else {
            setIsDrawActivated(false);
            const images = document.querySelectorAll('#pdfContainer img');
            if (images.length) {
                _.forEach(images, element => {
                    element.style.pointerEvents = 'auto';
                })
            }
        }

        const handleClickEvent = (e) => {
            if (sessionStorage.getItem('currentPreview') === 'draw') {
                const currentPage = e.target.closest('.previewArea');
                if (currentPage) {
                    const screenWidth = window.innerWidth;
                    const screenHeight = window.innerHeight;
                    const canvas = document.createElement('canvas');
                    // canvas.width = screenWidth;
                    // canvas.height = screenHeight;
                    canvas.style.position = 'absolute';
                    canvas.style.top = '0';
                    canvas.style.left = '0';
                    canvas.style.zIndex = '100';
                    canvas.style.background = 'transparent';
                    currentPage.style.cursor = 'crosshair';
                    currentPage.appendChild(canvas);

                    const ctx = canvas.getContext('2d');
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = drawState?.selectedColor || 'red';

                    let isDrawing = false;
                    let startX = 0, startY = 0;
                    let minX = screenWidth, minY = screenHeight, maxX = 0, maxY = 0;

                    const updateBoundingBox = (x, y) => {
                        if (x < minX) minX = x;
                        if (y < minY) minY = y;
                        if (x > maxX) maxX = x;
                        if (y > maxY) maxY = y;
                    };

                    const startDrawing = (e) => {
                        isDrawing = true;
                        const rect = canvas.getBoundingClientRect();
                        startX = e.clientX - rect.left;
                        startY = e.clientY - rect.top;
                        ctx.beginPath();
                        ctx.moveTo(startX, startY);
                        updateBoundingBox(startX, startY);
                    };

                    const draw = (e) => {
                        if (!isDrawing) return;
                        const rect = canvas.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = e.clientY - rect.top;
                        ctx.lineTo(x, y);
                        ctx.stroke();
                        updateBoundingBox(x, y);
                        startX = x;
                        startY = y;
                    };

                    const stopDrawing = (e) => {
                        if (isDrawing) {
                            isDrawing = false;
                            const rect = canvas.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;
                            ctx.lineTo(x, y);
                            ctx.stroke();
                            ctx.closePath();

                            updateBoundingBox(x, y);

                            const drawnWidth = maxX - minX;
                            const drawnHeight = maxY - minY;

                            if (drawnWidth > 0 && drawnHeight > 0) {
                                const resizedCanvas = document.createElement('canvas');
                                resizedCanvas.width = (drawnWidth + 15);
                                resizedCanvas.height = (drawnHeight + 15);
                                resizedCanvas.style.zIndex = '100';
                                const resizedCtx = resizedCanvas.getContext('2d');

                                resizedCtx.drawImage(canvas, minX, minY, drawnWidth, drawnHeight, 0, 0, drawnWidth, drawnHeight);

                                const imageDataURL = resizedCanvas.toDataURL('image/png');
                                const imgElement = document.createElement('img');
                                imgElement.src = imageDataURL;

                                imgElement.style.position = 'absolute';
                                imgElement.style.top = `${minY}px`;
                                imgElement.style.left = `${minX}px`;
                                imgElement.style.zIndex = '101';

                                currentPage.removeChild(canvas);
                                resizedCanvas.style.position = 'absolute';
                                resizedCanvas.style.top = `${minY}px`;
                                resizedCanvas.style.left = `${minX}px`;
                                currentPage.style.cursor = 'default';
                                currentPage.appendChild(imgElement);
                                reset();
                            }
                        }
                    };

                    canvas.addEventListener('mousedown', startDrawing);
                    canvas.addEventListener('mousemove', draw);
                    canvas.addEventListener('mouseup', stopDrawing);
                    canvas.addEventListener('mouseleave', stopDrawing);
                }
            }
        };

        window.addEventListener('click', handleClickEvent);

        return (() => {
            window.removeEventListener('click', handleClickEvent);
        });

    }, [drawState, isSectionActivated]);

    let prevLayoutController = null;

    useEffect(() => {
        const previewArea = document.querySelectorAll('.previewArea');
        if (previewArea?.length && prevLayoutController !== layoutController && !domUpdated.pdf) {
            if (layoutController) {
                _.forEach(previewArea, item => {
                    item.removeAttribute("isLandscape");
                    item.style.width = previewArea[0].offsetWidth > 150 ? `${previewArea[0].offsetWidth}px` : `${48 * 7}px`;
                    item.style.height = previewArea[0].offsetHeight > 150 ? `${previewArea[0].offsetHeight}px` : `${48 * 8.6}px`;
                })
            } else {
                _.forEach(previewArea, item => {
                    item.setAttribute("isLandscape", "true");
                    item.style.width = previewArea[0].offsetWidth > 150 ? `${previewArea[0].offsetWidth}px` : `${48 * 8}px`;
                    item.style.height = previewArea[0].offsetHeight > 150 ? `${previewArea[0].offsetHeight}px` : `${48 * 4.5}px`;
                })
            }
            prevLayoutController = layoutController;
        } else if (previewArea?.length) {
            _.forEach(previewArea, item => {
                item.style.width = `auto`;
                item.style.height = `auto`;
            })
        }
    }, [layoutController, domUpdated]);

    if (info.name === 'main_frame') {
        html = (
            <React.Fragment>
                <div
                    id='pdfContainer'
                    name={info.name}
                    className={`previewArea shadow-lg pdfPage-${pageIndex} pdfPage border-[1.5px] border-dashed border-[#A4DC84]`}
                    style={{
                        position: 'relative',
                        marginTop: '13px',
                        background: info.color,
                        zIndex: info.z_index,
                        overflow: 'hidden'
                    }}
                    onClick={(event) => { handleMainFrame(info); handlePdfPageController(event); }}
                    onMouseOver={handlePdfPageController}
                    onMouseOut={handlePdfPageController}
                    onMouseDown={handlePdfPageController}
                    onMouseUp={handlePdfPageController}
                    onMouseMove={handleMouseMove}
                >
                </div >
            </React.Fragment>
        );
    }
    return html;
}
